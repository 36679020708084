<template>
  <div class="user">
    <el-button type="primary" @click="$router.back()">返回</el-button>
    <div class="title_div">
      <p class="user_p">登录日志</p>
    </div>
    <div class="contentBox">
      <Table
        class="userAdmin"
        ref="tableComponent"
        :table-data="tableData"
        :columns="columns"
        :total="pageList.count"
        :page.sync="pageList.page"
        :rows.sync="pageList.rows"
        :loading="false"
        :flag="false"
        :searchLeftShow="true"
        :leftShow="false"
        :popperClass="`paginaClass`"
        border
        @pagination="getPage"
        @searchList="getPage"
      >
        <!-- 左上角按钮 -->
        <template  slot="searchJournal">
            <div class="search-box">
                <el-select
                v-model="entitys.querySelect"
                placeholder="请选择"
                value-key="prop"
                :popper-append-to-body="false"
                clearable
                @change="selectChange"
                >
                <el-option
                    v-for="item in selectOptions"
                    :key="item.prop"
                    :label="item.label"
                    :value="item.prop" />
            </el-select>
            <el-select
            v-if='successShow'
            v-model="entitys.queryValue"
            placeholder="请选择"
            value-key="prop"
            :popper-append-to-body="false"
            style="margin-right:12PX"
            clearable
            >
            <el-option
                v-for="item in selectSuccess"
                :key="item.prop"
                :label="item.label"
                :value="item.prop" />
            </el-select>
            <el-input
            v-if="!successShow"
            v-model.trim="entitys.queryValue"
            placeholder="请输入"
            style="margin: 0 12px 0 0"
            :disabled="inputDisab"
            clearable />
            <el-button type="primary" @click="proving()">查询</el-button>
            <el-button type="primary" @click="elTableRefs()">重置</el-button>
            </div>
        </template>
        <!-- 列表展示项 -->
        <template slot="columnEspecial" slot-scope="{ column, row }">
          <span v-if="column.prop === 'account'">{{ row[column.prop] }}</span>
          <span v-else-if="column.prop === 'success'">{{
            row[column.prop] === 1 ? "成功" : "失败" 
          }}</span>
          <span v-else>{{ row[column.prop] }}</span>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import Table from "@/components/Table";
import {
 getJournalPage
} from "@/api/login";
export default {
  components: {
    Table,
  },
  data() {
    return {
      successShow:false,
      inputDisab:true,
      entitys:{
          querySelect:'',
          queryValue:''
      },
       selectOptions: [
        {
          label: "登录账号",
          prop: "account",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: false,
          align: "center",
        },
        {
          label: "ip地址",
          prop: "ip",
          align: "center",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: false
        },
        {
          label: "登录城市",
          prop: "city",
          align: "center",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: false
        },
         {
          label: "是否成功",
          prop: "success",
          align: "center",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: false
        }
      ],
      selectSuccess: [
         {
          label: "成功",
          prop: "1",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: false,
          align: "center",
        },
        {
          label: "失败",
          prop: "0",
          align: "center",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: false
        },
      ],
      pageList: {
        page: 1,
        rows: 20,
        count: 0,
      },
      columns: [
        {
          label: "记录时间",
          prop: "createTm",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true,
        },
        {
          label: "登录账号",
          prop: "account",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true,
        },
        {
          label: "登录ip",
          prop: "ip",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true,
        },
        {
          label: "登录城市",
          prop: "city",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true,
        },
        {
          label: "密码错误次数",
          prop: "errorTimes",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true,
          width:'50'
        },
         {
          label: "是否登录成功",
          prop: "success",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true,
          width:'50'
        },
        {
          label: "浏览器信息",
          prop: "browserInfo",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true,
          width:'200'
        }
      ],
      tableData: [],
    };
  },
  mounted() {
    this.getPage();
  },
  methods: {
    selectChange() {
      this.inputDisab = false
      if(this.entitys.querySelect == 'success'){
          this.successShow = true
          this.entitys.queryValue = '1'
        }else{
          this.successShow = false
          this.entitys.queryValue = ''
        }
    },
    proving(){
        this.pageList.page = 1
        this.pageList.rows = 20
        this.getPage()
    },
    elTableRefs(){
        this.entitys.querySelect = ''
        this.entitys.queryValue = ''
        this.successShow = false
        this.pageList.page = 1
        this.pageList.rows = 20
        this.getPage()
    },
    async getPage(entity, searchButton) {
    //   const params = handleEntitys(entity || this.$refs.tableComponent.entitys);
    //   params.entity = {
    //     ...params.entity,
    //     // orgNo: this.selectData.orgNo
    //   };
    // typeof value === "number"
        const params = {}
        let obj = {}
        if(this.entitys.querySelect){
            obj[this.entitys.querySelect] = this.entitys.queryValue
            params.entity = obj
        }
      if (searchButton) {
          if(searchButton === 1){
            this.pageList.page = 1
              params.page = 1
              params.rows = entity.rows;
          }else if(searchButton === 2){
              params.page = entity.page;
              params.rows = this.pageList.rows
          }
      } else {
        params.page = this.pageList.page;
        params.rows = this.pageList.rows;
      }
      getJournalPage(params).then((res) => {
        this.pageList.page = res.currentPage;
        this.pageList.count = res.count;
        this.tableData = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search-box{
    display: flex;
    align-items: center;
    ::v-deep{
        .el-select{
            height: 40PX;
        }
        .el-input{
            height: 40PX;
        }
        .el-input__inner{
            height: 40PX;
            width: 170PX;
            margin-top: 0;
            color: #606266;
        }
        .el-input__icon{
            height: 40PX;
            line-height: 40PX;
        }
        .el-popper{
            background: #FFF !important;
        }
        .el-select-dropdown__item {
            color: #606266 !important;
        }
        .selected{
          span{
            color: #606266 !important;
          }
        }
    }
}
.user {
  padding: 25px 50px 25px 50px;
}
.title_div {
  margin-bottom: 20px;
}
.user_p {
  font-size: 22px;
  text-align: center;
  color: #606266;
}

::v-deep .contentBox {
  .el-table th,
  .el-table tr {
    background: #fff !important;
    color: #606266 !important;
  }
  .is-scrolling-none {
    background: #fff !important;
  }
    .el-table__row:hover{
        td{
            background: #ced8ea !important;
        }
    }
  .pagination-container {
    .el-pagination {
      .el-pager {
        .number {
          background: #fff !important;
          color: #000;
        }
        .active {
          background: #fff !important;
          color: #409eff !important;
        }
        li{
            color: #606266 !important;
        }
      }
      .paginaClass{
          background: #FFF !important;
      }
    }
  }
  .el-table__empty-block{
    background-color: #fff;
  }
}

::v-deep .table .tableBox,
::v-deep .table .el-table th.is-leaf {
  border: 1px solid #eaeefb;
  background: transparent;
}

//分页样式
::v-deep .table .pagination-container .el-input__inner,
.table .pagination-container .el-pagination__jump,
::v-deep .table .el-pagination__total,
::v-deep .el-pagination__jump {
  color: #606266 !important;
}
</style>
<style lang="scss">
// .el-popper{
 .paginaClass{
      background: #FFF !important;
      .selected{
        color: #409EFF !important;
      }
      .el-select-dropdown__item{
        color: #606266;
      }
  }
</style>